@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

@font-face {
  font-family: 'reina';
  src: url('./assets/fonts/ReinaNeue.otf') format('opentype')
}
@font-face {
  font-family: 'gilmerLight';
  src: url('./assets/fonts/Gilmer-Light.otf') format('opentype')
}
@font-face {
  font-family: 'gilmerRegular';
  src: url('./assets/fonts/Gilmer-Regular.otf') format('opentype')
}

* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
}

